<template>
  <div class="container box pt-3 pl-5 pr-5">
    <span class="span-color">Média geral das notas</span>
    <div class="d-flex justify-content-around align-itens-center mt-2">
      <div v-for="(number, index) in numbers" :key="index" class="circle-vote">
        <p class="font-weight-bold">{{number}}</p>
        <img v-if="averageVote === number" src="@/assets/notifications/triangule.svg" alt="triangle" class="image-triangle">
      </div>
    </div>
    <div class="line mt-3 mb-3"></div>
  </div>
</template>

<script>
export default {
  props: {
    votes: {
      type: Array
    }
  },
  data() {
    return {
      numbers: [0,1,2,3,4,5,6,7,8,9,10],
      votesReduce: []
    };
  },
  computed: {
    averageVote() {
      if (!this.votes.length) {
        return 0;
      }
      this.votes.map(vote => this.votesReduce.push(vote.vote))
      const promot = Math.round((this.votesReduce.filter(vote => vote >= 9).length * 100) / this.votes.length);
      const detra = Math.round((this.votesReduce.filter(vote => vote <= 6).length * 100) / this.votes.length);
      return Math.floor((promot - detra) / 10);
    }
  }
}
</script>

<style scoped>
.box {
  background-color: #e6e7e9;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  max-width: 648px;
  max-height: 120px;  
  position: relative;
  bottom: 130px;
  right: -200px;
  margin-bottom: -130px;
}

.span-color {
  color: #5f667a;
}

.circle-vote p {
  transform: translate(0, 25%);
}
.circle-vote {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #a7a9ac;
  background-color: #bcbdc0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #646567;
}

.line {
  border: 1px solid #57585a;
}

.image-triangle {
  width: 20px;
  height: 15px;
  position: absolute;
  bottom: 15px;
}

@media screen and (max-width: 1220px) {
  .box {
    top: 0;
    left: 0;
  }
}
</style>